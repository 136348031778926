import AboutContainer from 'components/HOC/about_container';
import raw from 'utils/raw';
import React from 'react';
import typograf from 'utils/typograf';
import {
  string, arrayOf, shape, bool,
} from 'prop-types';

const About = ({ data, reverse }) => (
  <AboutContainer reverse={reverse}>
    <ul className="about__list">
      {/* 1 */}
      <li className="about__item">
        <div className="about__content">
          <h4 className="about__title">
            {typograf(data[0].title)}
          </h4>

          <div className="about__text">{raw(typograf(data[0].text))}</div>
        </div>
        <img src={data[0].image} alt="" className="about__image" />
      </li>

      {/* 2 */}
      <li className="about__item">
        <img src={data[1].image} alt="" className="about__image" />

        <div className="about__content">
          <h4 className="about__title">
            {raw(typograf(data[1].title))}
          </h4>

          <div className="about__text">{raw(typograf(data[1].text))}</div>
        </div>
      </li>

      {/* 3 */}
      <li className="about__item">
        <div className="about__content">
          <h4 className="about__title">
            {typograf(data[2].title)}
          </h4>

          <div className="about__text">{raw(typograf(data[2].text))}</div>
        </div>
        <img src={data[2].image} alt="" className="about__image" />
      </li>

    </ul>
  </AboutContainer>
);

About.propTypes = {
  reverse: bool,
  data: arrayOf(shape({
    image: string.isRequired,
    text: string.isRequired,
    title: string.isRequired,
  })).isRequired,
};

About.defaultProps = {
  reverse: false,
};

export default About;
