import AniLink from 'gatsby-plugin-transition-link/AniLink';
import raw from 'utils/raw';
import React from 'react';
import { string, shape } from 'prop-types';

import settings from 'utils/settings';

const AdjacentNav = ({ page }) => {
  if (!page) return <></>;
  const { next, previous } = page;

  return (
    <ul className="adjacent_nav">
      {previous && (
        <li className="adjacent_nav__item adjacent_nav__item-prev">
          <AniLink
            cover
            bg={settings.brandGradient}
            direction="left"
            to={previous.frontmatter.slug}
            className="adjacent_nav__link"
          >
            <span className="adjacent_nav__label">
              {
                raw(`Предложение<br>${previous.frontmatter.hint}`)
              }
            </span>
            <img src={previous.frontmatter.icon} alt="" className="adjacent_nav__icon" />
          </AniLink>
        </li>
      )}

      {next && (
        <li className="adjacent_nav__item adjacent_nav__item-next">
          <AniLink
            cover
            bg="#38C9F2"
            direction="left"
            to={next.frontmatter.slug}
            className="adjacent_nav__link"
          >
            <span className="adjacent_nav__label">
              {
                raw(`Предложение<br>${next.frontmatter.hint}`)
              }
            </span>
            <img src={next.frontmatter.icon} alt="" className="adjacent_nav__icon" />
          </AniLink>
        </li>
      )}
    </ul>
  );
};

AdjacentNav.propTypes = {
  page: shape({
    next: {
      frontmatter: {
        slug: string.isRequired,
      },
    },
    prev: {
      frontmatter: {
        slug: string.isRequired,
      },
    },
  }).isRequired,
};

export default AdjacentNav;
