import React from 'react';
import { string, oneOf } from 'prop-types';

import ScenarioList from 'components/blocks/scenario_list';

const Scenario = ({ name }) => (
  <div className="scenario">
    <h4 className="scenario__title">Типовой сценарий работы</h4>
    <ScenarioList name={name} />
  </div>
);

Scenario.propTypes = {
  name: oneOf(['business', 'entepreneur'], string).isRequired,
};

export default Scenario;
