import HTML from 'components/blocks/html';
import raw from 'utils/raw';
import React from 'react';
import Scenario from 'components/blocks/scenario';
import typograf from 'utils/typograf';
import { string, oneOf } from 'prop-types';

import Container from 'components/layout/container';

const Decision = ({ content, title, name }) => (
  <Container>
    <div className="decision">
      <h4 className="decision__title">
        {raw(typograf(title))}
      </h4>

      <div className="decision__body">
        <HTML>{content}</HTML>
      </div>
      <div className="decision__footer">
        <Scenario name={name} />
      </div>

    </div>
  </Container>
);

Decision.propTypes = {
  content: string.isRequired,
  name: oneOf(['business', 'entepreneur'], string).isRequired,
  title: string.isRequired,
};

export default Decision;
