import React from 'react';
import typograf from 'utils/typograf';
import { string, oneOf } from 'prop-types';

import businessScenario from 'data/business/scenario.yaml';
import entrepreneurScenario from 'data/entrepreneur/scenario.yaml';

const scenario = {
  business: {
    scenario: businessScenario,
  },
  entrepreneur: {
    scenario: entrepreneurScenario,
  },
};

const ScenarioList = ({ name }) => (
  <ul className="scenario_list">
    {scenario[name].scenario.map(({ caption, icon }, i) => (
      <li className="scenario_list__item" key={icon}>

        <figure className="scenario_list__step">

          <span style={{ backgroundImage: `url(${icon})` }} className="scenario_list__icon" />

          <figcaption className="scenario_list__caption">
            <span className="scenario_list__index">{`${i + 1}. `}</span>
            <span className="scenario_list__text">{typograf(caption)}</span>
          </figcaption>

        </figure>
      </li>
    ))}
  </ul>
);

ScenarioList.propTypes = {
  name: oneOf(['business', 'entepreneur'], string).isRequired,
};

export default ScenarioList;
