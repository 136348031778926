import React from 'react';
import { graphql } from 'gatsby';
import { shape, string } from 'prop-types';

import About from 'components/blocks/about';
import Decision from 'components/blocks/decision';
import Layout from 'components/layout';
import PageHeader from 'components/blocks/page_header';
import OffersTitle from 'components/blocks/offers_title';
import SEO from 'components/seo';

import aboutBusiness from 'data/business/about.yaml';
import aboutEntrepreneur from 'data/entrepreneur/about.yaml';

const dataAbout = {
  business: {
    about: aboutBusiness,
  },
  entrepreneur: {
    about: aboutEntrepreneur,
  },
};

const Template = ({
  data, pageContext,
}) => {
  const {
    frontmatter: {
      decisionContent,
      decisionTitle,
      description,
      image,
      seoTitle,
      title,
      type,
    },
    html,
    parent: {
      name,
    },
  } = data.markdownRemark;

  return (
    <Layout pageType={type}>
      <div className="offers_page">
        <SEO title={seoTitle || title} description={description} />

        <PageHeader
          html={html}
          page={pageContext}
          image={image}
          header={<OffersTitle title={title} />}
          fade
        />

        <About data={dataAbout[name].about} reverse />
        <Decision title={decisionTitle} content={decisionContent} name={name} />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
       parent {
        ... on File {
          name
        }
      }
      frontmatter {
        decisionContent
        decisionTitle
        description
        image
        seoTitle
        title
        type
      }
    }
  }
`;

Template.propTypes = {
  data: shape({
    markdownRemark: shape({
      frontmatter: shape({
        description: string,
        image: string.isRequired,
        seoDescription: string,
        seoTitle: string,
        title: string.isRequired,
        type: string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
};

export default Template;
