import cn from 'classnames';
import React from 'react';
import { node, bool } from 'prop-types';

import Container from 'components/layout/container';

const AboutContainer = ({ children, reverse }) => {
  const aboutCN = cn('about about-masked', { 'about-reverse': reverse });

  return (
    <div className={aboutCN}>
      <div className="about__inner">
        <Container>{children}</Container>
      </div>
    </div>
  );
};

AboutContainer.propTypes = {
  children: node.isRequired,
  reverse: bool,
};

AboutContainer.defaultProps = {
  reverse: false,
};

export default AboutContainer;
