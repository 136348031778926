import React from 'react';
import { string, node } from 'prop-types';

import AdjacentNav from 'components/blocks/adjacent_nav';
import Container from 'components/layout/container';
import HTML from 'components/blocks/html';

const OffersHeader = ({
  html, page, image, header,
}) => (
  <Container>
    <div className="page_header">
      {page
        && (
        <div className="page_header__nav">
          <AdjacentNav page={page} />
        </div>
        )}

      {header}

      {html && (
        <div className="page_header__body">
          <div className="page_header__left">
            <HTML>{html}</HTML>
          </div>

          <div className="page_header__right">
            <img src={image} alt="" className="page_header__image" />
          </div>
        </div>
      )}
    </div>
  </Container>
);

OffersHeader.propTypes = {
  header: node.isRequired,
  html: node,
  image: string.isRequired,
};

OffersHeader.defaultProps = {
  html: null,
};

export default OffersHeader;
