import React from 'react';
import { string } from 'prop-types';

const OffersTitle = ({ title }) => (
  <h1 className="offers_title">
    <span className="offers_title__label">
          Бухгалтерия
      <br />
          нового поколения
    </span>
    <br />
    <span className="offers_title__text">{title}</span>
  </h1>
);

OffersTitle.propTypes = {
  title: string.isRequired,
};

export default OffersTitle;
